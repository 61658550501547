* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }
*::-webkit-input-placeholder {
	color: $d;
	opacity: 1; }
*:-moz-placeholder {
	color: $d;
	opacity: 1; }
*::-moz-placeholder {
	color: $d;
	opacity: 1; }
*:-ms-input-placeholder {
	color: $d;
	opacity: 1; }
*::selection {
  color: $l;
  background: $d; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: $l; }
body input:required:valid,
body textarea:required:valid {
	color: $d; }

html, body {
	height: 100%;
	width: 100%;
	margin: 0;
	overflow: hidden;
	-webkit-overflow-scrolling: touch; }
*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px lighten($a, 80%); }
*::-webkit-scrollbar {
	width: 4px; }
*::-webkit-scrollbar-thumb {
	background-color: $a; }
body {
	font-size: 16px;
	min-width: 320px;
	position: relative;
	line-height: 1.2;
	font-family: $default-font;
	opacity: 1;
	color: $l;
	font-weight: 300; }
h1,h2,h3,h4,h5,h6,ul,menu,li {
	margin: 0;
	padding: 0;
	display: block; }
label {
	cursor: pointer; }
input, textarea, a, button, select {
	outline: none; }
a {
	text-decoration: none;
	color: $d; }
ul {
  padding: 0;
  margin: 0; }
li {
	list-style-type: none;
	padding: 0;
	margin: 0; }
p {
	margin: 0 0 20px 0;
	padding: 0; }
p:last-child {
	margin-bottom: 0; }
select {
  appearance: none; }
address {
  font-style: normal; }
img {
	image-rendering: crisp-edges; }
