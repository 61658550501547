@import "../vars";
@import "colors";

.text-d {
  color: $dark; }
.text-l {
  color: $light; }
.text-a {
  color: $a; }

.text-grey {
  color: $grey; }
.text-lightgrey {
  color: $light-grey; }

.text-green {
  color: $green; }
.text-white {
  color: $white; }
.text-black {
  color: $black; }

.text-center {
  text-align: center; }
.text-left {
  text-align: left; }
.text-right {
  text-align: right; }
