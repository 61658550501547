$default-font: "montserrat-regular", sans-serif;
$d: #181819;
$l: #fff;
$a: #e16500;
$bord-l: 1px solid #ebebeb;
$bord-d: 1px solid $d;
$bord-a: 1px solid $a;
$input-d: #1c2431;
$shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08);
$radius: 2px;
$space: 20px;
// Grid variables
$grid-column: 12;
$prefix: xl, lg, md, sm, xs;
$xl-cont-size: 1200px;
$brakepoint: 1200px, 992px, 768px, 480px, 320px;
$cont-sizes: 992px, 768px, 100%, 100%, 100%;

$xl-cont-size-w: 1700px;
$brakepoint-w: 1700px, 1400px, 1200px, 992px, 768px, 480px, 320px;
$cont-sizes-w: 1400px, 1200px, 992px, 768px, 100%, 100%, 100%;
