.active-link {
  &::before, &::after {
    width: 50%; } }

.mob-mnu {
  .active-link {
    .link {
      &::before, &::after {
        width: 50%; } } } }

.mob-mnu-active {
  .page {
    transform: translateX(-80%); }
  .blocker {
    z-index: 9998;
    opacity: 1; } }

.hamburger-active {
	&::after {
		transform: rotate(45deg);
		top: 5px; }
	&::before {
		transform: rotate(-45deg);
		top: 5px; }
	.inner-hamb {
		opacity: 0;
		transform: translateX(100px); } }
.appender-popup-wrap {
  .blocker-popup {
    z-index: 9991;
    opacity: 1; } }

.popup-open-popup {
  .popup-opened-open-popup {
    opacity: 1;
    transform: translate(0); }
  .blocker {
    z-index: 9991;
    opacity: 1; } }

.active-slide {
	.item {
		opacity: 1; } }

.notification-visible {
  transform: translate(0);
  opacity: 1; }

.slider-img-active {
  cursor: none;
  .img-active {
    right: 0!important;
    width: 100%!important;
    &:hover {
      opacity: 1!important;
      cursor: none!important; } }
  .ls-nav-left-arrow, .ls-nav-right-arrow {
    opacity: 0!important; }
  .desc-hidden {
    .description {
      opacity: 0; } }
  .close {
    display: block!important;
    cursor: none;
    pointer-events: none; } }

.active-submenu {
  .angle-bottom {
    transform: rotate(180deg);
    top: 24px!important; } }

.ready {
  #sect-1 {
    .h1 {
      transform: rotateX(0deg);
      opacity: 1; }
    .h2 {
      transform: translateX(0);
      opacity: 1; }
    .btn-a {
      transform: translateY(0);
      opacity: 1; } } }
