@import "fonts";
@import "vars";
@import "resets";
@import "grid";
@import "preloader";
@import "_mixins/btns";
@import "_mixins/links";
@import "_mixins/icons";
@import "_mixins/dynamic-grid";
@import "_optional/colors";
@import "_optional/typograph";
@import "base";
@import "btn_forms_elem";
@import "_optional/flex";
@import "titles";
@import "fixed";
@import "libs";
@import "animator";

#sect-1 {
	height: calc(100% + 30px);
	background-size: cover;
	background-position: center;
	position: relative;
	padding: 0;
	.h1 {
		transform: rotateX(90deg);
		transition: 0.5s 0.5s;
		opacity: 0; }
	.h2 {
		transform: translateX(50%);
		opacity: 0;
		transition: 0.5s 1s; }
	.btn-a {
		transform: translateY(100%);
		opacity: 0;
		transition: 0.5s 1.5s; }
	.bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: calc(100% - 100px);
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		background-color: $d; } }
.dark {
	background-color: $d;
	.cont-fluid-w {
		padding: 0 100px; }
	.title {
		margin-bottom: 150px; } }
.pluses {
	position: absolute;
	bottom: 0;
	padding: 0 $space; }
.plus {
	background: $l;
	color: $d;
	box-shadow: 0 5px 10px lighten($d, 70%);
	z-index: 100;
	padding: 0 0 0 $space * 4;
	border-radius: $radius;
	img {
		margin-bottom: $space;
		height: 35px;
		width: auto; }
	.item-block {
		padding: $space $space $space 0; } }
#sect-plus {
	padding: 0;
	.item-block {
		img {
			transition: 0.5s; }
		&:hover {
			img {
				transform: translateY(-10px); } } } }
#sect-2 {
	.h1 {
		margin-bottom: $space * 3; }
	.lig-slide {
		position: relative; }
	.close {
		position: absolute;
		transition: 0s;
		z-index: 99;
		display: none;
		&::after, &::before {
			background-color: $d; } }
	.slider-img {
		width: 50%;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		transition: 0.5s;
		cursor: pointer;
		img {
			height: 100%;
			width: auto;
			transition: 0.5s; }
		&:hover {
			opacity: 0.7; } }
	.description {
		width: 50%;
		height: 100%;
		padding: $space * 3;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: 0.5s; }
	.slider-price {
		color: $a; }
	.btn-l {
		margin-left: $space / 2;
		border: 1px solid $d; } }
.sl-price {
	margin: $space / 2 0; }
.text-a.sl-price {
	font-size: 20px; }

#sect-3 {
	.btn-d-big, .btn-l-big {
		width: 400px; }
	.thermPrice {
		margin-bottom: 40px; } }
.innerCallback {
	margin: $space * 3 0; }
#sect-4 {
	background-size: cover;
	span {
		color: $l; }
	.sect4-subt {
		display: flex;
		align-items: center;
		span {
			margin-left: $space;
			font-size: 30px; }
		.sect4-num {
			font-size: 100px;
			font-weight: 600;
			margin: 0; } } }
#sect-5 {
	.cont-fluid {
		padding: 0;
		&:nth-child(2) {
			padding: 0 $space; } }
	.factory-image {
		padding: $space;
		transition: transform 0.5s;
		&:hover {
			transform: scale(1.2); } }
	img {
		width: 100%;
		border-radius: $radius;
		box-shadow: 0 0 30px lighten($d, 70%); }
	.manufac-desc {
		padding: $space $space 0 $space; } }
#sect-6 {
	background-size: cover;
	.row {
		img {
			width: auto;
			height: 70px;
			margin-bottom: $space * 2; }
		.items {
			padding: 0 $space * 2 0 0; } } }
#sect-7 {
	.h4 {
		text-align: center;
		margin-bottom: 40px; }
	form {
		text-align: center;
		box-shadow: 0 0 30px lighten($d, 70%);
		padding: $space;
		margin-bottom: $space * 2;
		border-radius: $radius;
		.flex {
			padding: $space 0; } }
	textarea {
		width: 100%;
		height: 100%; }
	.input-group {
		height: 150px;
		position: relative;
		.btn-d {
			position: absolute;
			bottom: 0;
			right: 0; } }
	label:first-child {
		margin-left: 0; }
	label {
		font-size: 20px;
		white-space: nowrap;
		margin-left: $space;
		margin-right: $space / 2; } }
.item-info {
	display: flex;
	align-items: center;
	margin-bottom: $space * 2;
	.icon {
		height: 30px;
		margin: 0;
		margin-right: $space; }
	.link {
		color: $d;
		text-transform: none;
		font-size: 20px;
		white-space: nowrap;
		&::after, &::before {
			background-color: $d; } } }
#sect-8 {
	padding-top: $space;
	#map {
		.ymaps-2-1-65-controls__control_toolbar, .ymaps-2-1-64-_hidden-text, .ymaps-2-1-64-float-button-text {
			display: none; } }
	padding-bottom: 0; }
.footer {
	background-color: $d;
	width: 100%;
	padding: 30px 0;
	.cont-fluid {
		align-items: normal; }
	.link {
		text-transform: none; }
	.footer-list {
		display: flex;
		justify-content: space-between;
		flex: 1; }
	li {
		li {
			padding: $space / 2 0; }
		img {
			height: 40px; } } }

.read-more {
	display: none;
	cursor: pointer; }

#gallery {
	padding: $space* 2 0 0 0; }

#notfound {
	height: 100%;
	.h1, .h2, .link {
		color: $d; } }

// Desc module
.desc {
	margin-top: $space * 2;
	width: 100%;
	.h1, h1 {
		font-size: 40px;
		text-align: center;
		margin-bottom: $space * 2;
		font-weight: 300; }
	.h3, h3 {
		font-size: 30px;
		margin-bottom: $space;
		margin-top: $space;
		font-weight: 300; }
	ul {
		display: block;
		list-style-type: disc;
		li {
			margin-bottom: $space / 2;
			padding: 0 0 0 $space * 2;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				height: 1px;
				width: 20px;
				background-color: $a; } } } }

#partners {
	padding: 0; }
.contacts {
	padding: $space 0; }
.contacts-item {
	padding-bottom: $space / 2;
	.link {
		color: $d;
		&::after, &::before {
			display: none; }
		&:hover {
			color: $a; } }
	strong {
		color: $d;
		font-weight: 600; } }
.partner {
	padding: 0 0 $space / 2 $space; }
.sliderTip {
	display: none; }


//Slider default style
.slider {
	width: 100%;
 }	// height: 100%
.viewport {
 }	// height: 100%
.slide-line {
	// height: 100%
	overflow: hidden; }
.slide {
	// height: 100%
	.img-center {
		width: 100%;
		// height: 100%
		img {
			width: 100%; } } }
.item {
	width: 100%;
	padding: 10px;
	opacity: 0;
	transition: 1s;
	padding: 0 50px 0 0;
	img {
		width: 100px;
		height: 100px;
		border-radius: 100px; }
	.h4 {
		margin: 20px 0; } }

.control {
	cursor: pointer;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	z-index: 300;
	transition: 0.5s;
	&:hover {
		transform: scale(1.2); } }
.prev {
	left: 30px; }
.next {
	right: 30px; }

.slider-dots {
	bottom: -30px;
	width: 100%;
	padding-top: 20px;
	left: 0;
	display: none;
	justify-content: flex-start;
	align-items: center;
	transition: 0.5s; }
.dot {
	width: 6px;
	height: 6px;
	background-color: $d;
	margin: 0 20px 0 0;
	border-radius: 20px;
	cursor: pointer;
	transition: 0.2s;
	&:hover {
		width: 10px;
		height: 10px; } }
.dot-active {
	width: 10px;
	height: 10px;
	justify-content: space-around;
	align-items: center; }

.filter {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80%;
	padding-top: 30px; }
.filter-link {
	color: $l;
	font-size: 24px;
	transition: 0.5s;
	position: relative;
	&::before,&::after {
		content: '';
		position: absolute;
		top: 4px;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 100%;
		height: 3px;
		background-color: $l;
		transition: 0.5s; }
	&:hover {
		&::before,&::after {
			left: -30px;
			width: 20px;
			height: 1px; } } }
.filter-active {
	&::before,&::after {
		left: -30px;
		width: 20px;
		height: 1px; } }

@import "anim";
@import "media";
