@import "../_vars";

$dark: $d;
$light: $l;
$accent: $a;

$black: #000;
$white: #fff;
$green: #35c445;
$grey: #9b9b9b;
$light-grey: #ebebeb;
