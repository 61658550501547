// btn (
/// 1 border-color,
/// 2 color,
/// 3 bg-color,
/// 4 hover-color,
/// 5 bg-hover-color,
/// 6 bg-active-color,
/// 7 border-radius,
/// 8 fz,
/// 9 pad,
/// 10 lh
// )

.btn-a {
	@include btn ($d, $d, $l, $d, $l, $l, 2px, 24px, $space $space * 2, 1);
	font-weight: 300;
	position: relative;
	overflow: hidden;
	&::before, &::after {
		content: 'Продукция и цены';
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		transition: transform 0.5s;
		border-radius: $radius; }
	&::after {
		background-color: $a;
		transform: translateX(-100%);
		color: $l; }
	&::before {
		color: $d;
		background-color: $l; }
	&:hover {
		&::after {
			transform: translate(0); }
		&::before {
			transform: translate(100%); } } }
.btn-d {
	@include btn ($d, $l, $d, $d, $l, $l, $radius, 16px, $space / 2 $space * 2, 1);
	font-weight: 300; }
.btn-l {
	@include btn ($l, $d, $l, $l, $d, $d, $radius, 16px, $space / 2 $space, 1);
	font-weight: 300; }
.btn-l-big {
	@include btn ($d, $d, $l, $l, $d, $d, $radius, 24px, $space, 1);
	font-weight: 300;
	margin-bottom: 20px; }
.btn-d-big {
	@include btn ($d, $l, $d, $d, $l, $l, $radius, 24px, $space, 1);
	font-weight: 300;
	margin-bottom: 20px; }
input {
	@include btn ($l, $d, transparent, $d, transparent, transparent, 0, 20px, 0 $space / 2, 1);
	font-weight: 300;
	text-align: left;
	border: none;
	border-bottom: 1px solid $d;
	box-shadow: none;
	width: 100%;
	&:hover, &:focus {
		border-bottom: 1px solid $a;
		box-shadow: none; } }
textarea, select {
	@include btn ($d, $d, $l, $d, $l, $l, $radius, 20px, $space / 2, 1);
	text-align: left;
	&::placeholder {
		color: $grey; } }
textarea {
	resize: none; }
.link {
	@include link ($l, 1px, 0);
	&::after, &::before {
		background-color: $a; } }
.select {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;
		border: {
			top: $bord-d;
			left: $bord-d; }
		top: 0;
		bottom: 0;
		margin: auto;
		right: 30px;
		z-index: 10;
		transform: rotate(45deg + 180deg);
		cursor: pointer;
		pointer-events: none; }
	select {
		width: 100%; }
	option {
		background-color: $d; } }
