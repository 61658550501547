.align-center, .sect.align-center, .cont-fluid.align-center {
  align-items: center; }
.align-start, .sect.align-start, .cont-fluid.align-start {
  align-items: flex-start; }
.align-end, .sect.align-end, .cont-fluid.align-end {
  align-items: flex-end; }

.content-center, .sect.content-center, .cont-fluid.content-center {
  justify-content: center; }
.content-start, .sect.content-start, .cont-fluid.content-start {
  justify-content: flex-start; }
.content-end, .sect.content-end, .cont-fluid.content-end {
  justify-content: flex-end; }
.content-sb, .sect.content-sb, .cont-fluid.content-sb {
  justify-content: space-between; }
.content-sa, .sect.content-sa, .cont-fluid.content-sa {
  justify-content: space-around; }

.column {
  flex-direction: column; }
.row {
  flex-direction: row; }

.flex {
  display: flex; }
.flex-wrap {
  flex-flow: row wrap; }
.bg-d {
  background-color: $d; }
.bord-l {
  border: 1px solid $l; }
