@import "vars";
.hidden {
	display: none; }
.visible-sm {
	display: none; }
.cont-fluid, .cont-fluid-w {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 1920px;
	margin: 0 auto;
	padding: 0 20px;
	z-index: 10;
	position: relative; }
.row {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	width: 100%;
	flex: 1; }

@media only screen and (max-width: 1000px) {
	.visible-sm {
		display: block; }
	.hidden-sm {
		display: none; } }

@media only screen and (max-width : 580px) {
	.hidden-xsm {
		display: none; } }
