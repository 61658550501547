@charset "UTF-8";
@font-face {
  font-family: "montserrat-regular";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/MontserratRegular/Montserrat-Regular.eot");
  src: url("../fonts/MontserratRegular/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratRegular/Montserrat-Regular.woff") format("woff"), url("../fonts/MontserratRegular/Montserrat-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "montserrat-regular";
  font-weight: light;
  font-style: normal;
  src: url("../fonts/MontserratLight/Montserrat-Light.eot");
  src: url("../fonts/MontserratLight/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratLight/Montserrat-Light.woff") format("woff"), url("../fonts/MontserratLight/Montserrat-Light.ttf") format("truetype"); }

@font-face {
  font-family: "montserrat-regular";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/MontserratSemibold/Montserrat-SemiBold.eot");
  src: url("../fonts/MontserratSemibold/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratSemibold/Montserrat-SemiBold.woff") format("woff"), url("../fonts/MontserratSemibold/Montserrat-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: "montserrat-regular";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/MontserratBlack/Montserrat-Black.eot");
  src: url("../fonts/MontserratBlack/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratBlack/Montserrat-Black.woff") format("woff"), url("../fonts/MontserratBlack/Montserrat-Black.ttf") format("truetype"); }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

*::-webkit-input-placeholder {
  color: #181819;
  opacity: 1; }

*:-moz-placeholder {
  color: #181819;
  opacity: 1; }

*::-moz-placeholder {
  color: #181819;
  opacity: 1; }

*:-ms-input-placeholder {
  color: #181819;
  opacity: 1; }

*::selection {
  color: #fff;
  background: #181819; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  color: #fff; }

body input:required:valid,
body textarea:required:valid {
  color: #181819; }

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch; }

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white; }

*::-webkit-scrollbar {
  width: 4px; }

*::-webkit-scrollbar-thumb {
  background-color: #e16500; }

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.2;
  font-family: "montserrat-regular", sans-serif;
  opacity: 1;
  color: #fff;
  font-weight: 300; }

h1, h2, h3, h4, h5, h6, ul, menu, li {
  margin: 0;
  padding: 0;
  display: block; }

label {
  cursor: pointer; }

input, textarea, a, button, select {
  outline: none; }

a {
  text-decoration: none;
  color: #181819; }

ul {
  padding: 0;
  margin: 0; }

li {
  list-style-type: none;
  padding: 0;
  margin: 0; }

p {
  margin: 0 0 20px 0;
  padding: 0; }

p:last-child {
  margin-bottom: 0; }

select {
  appearance: none; }

address {
  font-style: normal; }

img {
  image-rendering: crisp-edges; }

.hidden {
  display: none; }

.visible-sm {
  display: none; }

.cont-fluid, .cont-fluid-w {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1920px;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 10;
  position: relative; }

.row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 100%;
  flex: 1; }

@media only screen and (max-width: 1000px) {
  .visible-sm {
    display: block; }
  .hidden-sm {
    display: none; } }

@media only screen and (max-width: 580px) {
  .hidden-xsm {
    display: none; } }

.close {
  width: 40px;
  height: 40px;
  position: relative;
  transition: 0.5s;
  cursor: pointer; }
  .close::after, .close::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 5px;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .close::after {
    transform: rotate(45deg); }
  .close::before {
    transform: rotate(-45deg); }

.triangle-top {
  height: 0;
  width: 0;
  border: 20px solid transparent;
  border-bottom: 20px solid #e16500;
  transform-origin: 50% 30%;
  cursor: pointer; }

.triangle-bottom {
  height: 0;
  width: 0;
  border: 20px solid transparent;
  border-top: 20px solid #fff;
  transform-origin: 50% 70%;
  cursor: pointer; }

.triangle-left {
  height: 0;
  width: 0;
  border: 20px solid transparent;
  border-right: 20px solid #fff;
  transform-origin: 70% 50%;
  cursor: pointer; }

.triangle-right {
  height: 0;
  width: 0;
  border: 20px solid transparent;
  border-left: 20px solid #fff;
  transform-origin: 30% 50%;
  cursor: pointer; }

.triangle-top-right {
  height: 0;
  width: 0;
  cursor: pointer;
  border: 15px solid transparent;
  border-top: 15px solid #fff;
  border-right: 15px solid #fff; }

.triangle-top-left {
  height: 0;
  width: 0;
  cursor: pointer;
  border: 15px solid transparent;
  border-top: 15px solid #fff;
  border-left: 15px solid #fff; }

.triangle-bottom-right {
  height: 0;
  width: 0;
  cursor: pointer;
  border: 15px solid transparent;
  border-bottom: 15px solid #fff;
  border-right: 15px solid #fff; }

.triangle-bottom-left {
  height: 0;
  width: 0;
  cursor: pointer;
  border: 15px solid transparent;
  border-bottom: 15px solid #fff;
  border-right: 15px solid #fff; }

.angle-top {
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: relative; }
  .angle-top::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 30px;
    width: 30px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(45deg); }

.angle-bottom {
  height: 10px;
  width: 10px;
  cursor: pointer;
  position: relative; }
  .angle-bottom::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 10px;
    width: 10px;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg); }

.angle-left {
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: relative; }
  .angle-left::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 30px;
    width: 30px;
    border-bottom: 2px solid #181819;
    border-left: 2px solid #181819;
    transform: rotate(45deg); }

.angle-right {
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: relative; }
  .angle-right::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 30px;
    width: 30px;
    border-top: 2px solid #181819;
    border-right: 2px solid #181819;
    transform: rotate(45deg); }

.arrow-back {
  width: 40px;
  height: 40px;
  position: relative;
  transition: 0.5s;
  cursor: pointer; }
  .arrow-back::after, .arrow-back::before {
    content: '';
    position: absolute;
    margin: auto; }
  .arrow-back::after {
    height: 2px;
    width: 40px;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .arrow-back::before {
    width: 20px;
    height: 20px;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(45deg);
    top: 0;
    bottom: 0;
    left: 0; }

.arrow-next {
  width: 40px;
  height: 40px;
  position: relative;
  transition: 0.5s;
  cursor: pointer; }
  .arrow-next::after, .arrow-next::before {
    content: '';
    position: absolute;
    margin: auto; }
  .arrow-next::after {
    height: 2px;
    width: 40px;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .arrow-next::before {
    width: 20px;
    height: 20px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    top: 0;
    bottom: 0;
    right: 0; }

.arrow-up {
  width: 40px;
  height: 40px;
  position: relative;
  transition: 0.5s;
  cursor: pointer; }
  .arrow-up::after, .arrow-up::before {
    content: '';
    position: absolute;
    margin: auto; }
  .arrow-up::after {
    width: 2px;
    height: 40px;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .arrow-up::before {
    width: 20px;
    height: 20px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(45deg);
    top: 0;
    left: 0;
    right: 0; }

.arrow-down {
  width: 40px;
  height: 40px;
  position: relative;
  transition: 0.5s;
  cursor: pointer; }
  .arrow-down::after, .arrow-down::before {
    content: '';
    position: absolute;
    margin: auto; }
  .arrow-down::after {
    width: 2px;
    height: 40px;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .arrow-down::before {
    width: 20px;
    height: 20px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    bottom: 0;
    left: 0;
    right: 0; }

.cont-fluid {
  width: 1200px; }

.xl-col-1 {
  width: 8.33333%; }

.xl-col-2 {
  width: 16.66667%; }

.xl-col-3 {
  width: 25%; }

.xl-col-4 {
  width: 33.33333%; }

.xl-col-5 {
  width: 41.66667%; }

.xl-col-6 {
  width: 50%; }

.xl-col-7 {
  width: 58.33333%; }

.xl-col-8 {
  width: 66.66667%; }

.xl-col-9 {
  width: 75%; }

.xl-col-10 {
  width: 83.33333%; }

.xl-col-11 {
  width: 91.66667%; }

.xl-col-12 {
  width: 100%; }

@media only screen and (max-width: 1200px) {
  .cont-fluid {
    width: 992px; }
  .xl-col-1 {
    width: 8.33333%; }
  .xl-col-2 {
    width: 16.66667%; }
  .xl-col-3 {
    width: 25%; }
  .xl-col-4 {
    width: 33.33333%; }
  .xl-col-5 {
    width: 41.66667%; }
  .xl-col-6 {
    width: 50%; }
  .xl-col-7 {
    width: 58.33333%; }
  .xl-col-8 {
    width: 66.66667%; }
  .xl-col-9 {
    width: 75%; }
  .xl-col-10 {
    width: 83.33333%; }
  .xl-col-11 {
    width: 91.66667%; }
  .xl-col-12 {
    width: 100%; } }

@media only screen and (max-width: 992px) {
  .cont-fluid {
    width: 768px; }
  .lg-col-1 {
    width: 8.33333%; }
  .lg-col-2 {
    width: 16.66667%; }
  .lg-col-3 {
    width: 25%; }
  .lg-col-4 {
    width: 33.33333%; }
  .lg-col-5 {
    width: 41.66667%; }
  .lg-col-6 {
    width: 50%; }
  .lg-col-7 {
    width: 58.33333%; }
  .lg-col-8 {
    width: 66.66667%; }
  .lg-col-9 {
    width: 75%; }
  .lg-col-10 {
    width: 83.33333%; }
  .lg-col-11 {
    width: 91.66667%; }
  .lg-col-12 {
    width: 100%; } }

@media only screen and (max-width: 768px) {
  .cont-fluid {
    width: 100%; }
  .md-col-1 {
    width: 8.33333%; }
  .md-col-2 {
    width: 16.66667%; }
  .md-col-3 {
    width: 25%; }
  .md-col-4 {
    width: 33.33333%; }
  .md-col-5 {
    width: 41.66667%; }
  .md-col-6 {
    width: 50%; }
  .md-col-7 {
    width: 58.33333%; }
  .md-col-8 {
    width: 66.66667%; }
  .md-col-9 {
    width: 75%; }
  .md-col-10 {
    width: 83.33333%; }
  .md-col-11 {
    width: 91.66667%; }
  .md-col-12 {
    width: 100%; } }

@media only screen and (max-width: 480px) {
  .cont-fluid {
    width: 100%; }
  .sm-col-1 {
    width: 8.33333%; }
  .sm-col-2 {
    width: 16.66667%; }
  .sm-col-3 {
    width: 25%; }
  .sm-col-4 {
    width: 33.33333%; }
  .sm-col-5 {
    width: 41.66667%; }
  .sm-col-6 {
    width: 50%; }
  .sm-col-7 {
    width: 58.33333%; }
  .sm-col-8 {
    width: 66.66667%; }
  .sm-col-9 {
    width: 75%; }
  .sm-col-10 {
    width: 83.33333%; }
  .sm-col-11 {
    width: 91.66667%; }
  .sm-col-12 {
    width: 100%; } }

@media only screen and (max-width: 320px) {
  .cont-fluid {
    width: 100%; }
  .xs-col-1 {
    width: 8.33333%; }
  .xs-col-2 {
    width: 16.66667%; }
  .xs-col-3 {
    width: 25%; }
  .xs-col-4 {
    width: 33.33333%; }
  .xs-col-5 {
    width: 41.66667%; }
  .xs-col-6 {
    width: 50%; }
  .xs-col-7 {
    width: 58.33333%; }
  .xs-col-8 {
    width: 66.66667%; }
  .xs-col-9 {
    width: 75%; }
  .xs-col-10 {
    width: 83.33333%; }
  .xs-col-11 {
    width: 91.66667%; }
  .xs-col-12 {
    width: 100%; } }

.cont-fluid-w {
  width: 1700px; }

@media only screen and (max-width: 1700px) {
  .cont-fluid-w {
    width: 1400px; } }

@media only screen and (max-width: 1400px) {
  .cont-fluid-w {
    width: 1200px; } }

@media only screen and (max-width: 1200px) {
  .cont-fluid-w {
    width: 992px; } }

@media only screen and (max-width: 992px) {
  .cont-fluid-w {
    width: 768px; } }

@media only screen and (max-width: 768px) {
  .cont-fluid-w {
    width: 100%; } }

@media only screen and (max-width: 480px) {
  .cont-fluid-w {
    width: 100%; } }

@media only screen and (max-width: 320px) {
  .cont-fluid-w {
    width: 100%; } }

.text-d {
  color: #181819; }

.text-l {
  color: #fff; }

.text-a {
  color: #e16500; }

.text-grey {
  color: #9b9b9b; }

.text-lightgrey {
  color: #ebebeb; }

.text-green {
  color: #35c445; }

.text-white {
  color: #fff; }

.text-black {
  color: #000; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.page {
  overflow: hidden;
  transition: 0.4s;
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-overflow-scrolling: touch; }

.sect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  padding: 60px 0;
  -webkit-overflow-scrolling: touch; }

.items-page {
  padding: 60px 0 0 0; }

.screen-scroll {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; }

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.5s;
  padding-right: 20px; }

.scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white; }

.scrollable::-webkit-scrollbar {
  width: 4px; }

.scrollable::-webkit-scrollbar-thumb {
  background-color: #e16500; }

.screen-link {
  cursor: pointer; }

.polit {
  text-align: center;
  font-size: 8px;
  line-height: 1.2;
  color: #181819; }
  .polit .open-popup {
    border-bottom: 1px solid #181819;
    color: #181819; }

.pad-top {
  padding-top: 40px; }

.crumbs {
  padding: 20px;
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000; }
  .crumbs ul {
    width: 100%;
    display: flex;
    flex-flow: row wrap; }
  .crumbs .link {
    color: #181819; }
    .crumbs .link::after, .crumbs .link::before {
      background-color: #181819; }

.slider {
  position: relative;
  cursor: pointer;
  z-index: 999; }

.viewport {
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: 0.5s; }

.slide-line {
  transition: 0.5s;
  overflow-y: hidden; }

.slide {
  float: left; }

.text-d {
  color: #181819; }

.bg-overlay-d::after {
  content: '';
  position: absolute;
  background-color: #181819;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 1; }

.bg-overlay-l::after {
  content: '';
  position: absolute;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0.95;
  z-index: 1; }

span {
  color: #e16500; }

.item-flex {
  display: flex; }

.item-block {
  padding: 0 50px 0 0; }

.icon {
  height: 60px;
  margin-bottom: 40px; }
  .icon img {
    max-height: 100%; }

.tabbar {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  padding: 0 0 0 255px;
  display: flex;
  z-index: 200;
  flex-flow: row wrap; }

.tab-view {
  position: absolute;
  top: 98px;
  z-index: -100;
  opacity: 0;
  transform: translateY(100%);
  transition: 0.5s;
  width: 100%;
  height: calc(100% - 98px); }

.btn-a {
  border: 1px solid #181819;
  border-radius: 2px;
  color: #181819;
  background-color: #fff;
  cursor: pointer;
  font-size: 24px;
  white-space: nowrap;
  transition: 0.5s;
  padding: 20px 40px;
  line-height: 1;
  text-align: center;
  display: inline-block;
  font-weight: 300;
  position: relative;
  overflow: hidden; }
  .btn-a:hover, .btn-a:active, .btn-a:focus {
    color: #181819;
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  .btn-a:active, .btn-a:focus {
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  .btn-a::before, .btn-a::after {
    content: 'Продукция и цены';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: transform 0.5s;
    border-radius: 2px; }
  .btn-a::after {
    background-color: #e16500;
    transform: translateX(-100%);
    color: #fff; }
  .btn-a::before {
    color: #181819;
    background-color: #fff; }
  .btn-a:hover::after {
    transform: translate(0); }
  .btn-a:hover::before {
    transform: translate(100%); }

.btn-d {
  border: 1px solid #181819;
  border-radius: 2px;
  color: #fff;
  background-color: #181819;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  transition: 0.5s;
  padding: 10px 40px;
  line-height: 1;
  text-align: center;
  display: inline-block;
  font-weight: 300; }
  .btn-d:hover, .btn-d:active, .btn-d:focus {
    color: #181819;
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  .btn-d:active, .btn-d:focus {
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }

.btn-l {
  border: 1px solid #fff;
  border-radius: 2px;
  color: #181819;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  transition: 0.5s;
  padding: 10px 20px;
  line-height: 1;
  text-align: center;
  display: inline-block;
  font-weight: 300; }
  .btn-l:hover, .btn-l:active, .btn-l:focus {
    color: #fff;
    background-color: #181819;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  .btn-l:active, .btn-l:focus {
    background-color: #181819;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }

.btn-l-big {
  border: 1px solid #181819;
  border-radius: 2px;
  color: #181819;
  background-color: #fff;
  cursor: pointer;
  font-size: 24px;
  white-space: nowrap;
  transition: 0.5s;
  padding: 20px;
  line-height: 1;
  text-align: center;
  display: inline-block;
  font-weight: 300;
  margin-bottom: 20px; }
  .btn-l-big:hover, .btn-l-big:active, .btn-l-big:focus {
    color: #fff;
    background-color: #181819;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  .btn-l-big:active, .btn-l-big:focus {
    background-color: #181819;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }

.btn-d-big {
  border: 1px solid #181819;
  border-radius: 2px;
  color: #fff;
  background-color: #181819;
  cursor: pointer;
  font-size: 24px;
  white-space: nowrap;
  transition: 0.5s;
  padding: 20px;
  line-height: 1;
  text-align: center;
  display: inline-block;
  font-weight: 300;
  margin-bottom: 20px; }
  .btn-d-big:hover, .btn-d-big:active, .btn-d-big:focus {
    color: #181819;
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  .btn-d-big:active, .btn-d-big:focus {
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }

input {
  border: 1px solid #fff;
  border-radius: 0;
  color: #181819;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  white-space: nowrap;
  transition: 0.5s;
  padding: 0 10px;
  line-height: 1;
  text-align: center;
  display: inline-block;
  font-weight: 300;
  text-align: left;
  border: none;
  border-bottom: 1px solid #181819;
  box-shadow: none;
  width: 100%; }
  input:hover, input:active, input:focus {
    color: #181819;
    background-color: transparent;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  input:active, input:focus {
    background-color: transparent;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  input:hover, input:focus {
    border-bottom: 1px solid #e16500;
    box-shadow: none; }

textarea, select {
  border: 1px solid #181819;
  border-radius: 2px;
  color: #181819;
  background-color: #fff;
  cursor: pointer;
  font-size: 20px;
  white-space: nowrap;
  transition: 0.5s;
  padding: 10px;
  line-height: 1;
  text-align: center;
  display: inline-block;
  text-align: left; }
  textarea:hover, textarea:active, textarea:focus, select:hover, select:active, select:focus {
    color: #181819;
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  textarea:active, textarea:focus, select:active, select:focus {
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0, 1, 3, 0.08); }
  textarea::placeholder, select::placeholder {
    color: #9b9b9b; }

textarea {
  resize: none; }

.link {
  position: relative;
  color: #fff;
  transition: 0.5s; }
  .link::after, .link::before {
    content: '';
    transition: 0.3s;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    height: 1px;
    width: 0; }
  .link::before {
    left: 0; }
  .link::after {
    right: 0; }
  .link:hover::before, .link:hover::after {
    width: 50%; }
  .link::after, .link::before {
    background-color: #e16500; }

.select {
  position: relative; }
  .select::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 1px solid #181819;
    border-left: 1px solid #181819;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 30px;
    z-index: 10;
    transform: rotate(225deg);
    cursor: pointer;
    pointer-events: none; }
  .select select {
    width: 100%; }
  .select option {
    background-color: #181819; }

.align-center, .sect.align-center, .cont-fluid.align-center {
  align-items: center; }

.align-start, .sect.align-start, .cont-fluid.align-start {
  align-items: flex-start; }

.align-end, .sect.align-end, .cont-fluid.align-end {
  align-items: flex-end; }

.content-center, .sect.content-center, .cont-fluid.content-center {
  justify-content: center; }

.content-start, .sect.content-start, .cont-fluid.content-start {
  justify-content: flex-start; }

.content-end, .sect.content-end, .cont-fluid.content-end {
  justify-content: flex-end; }

.content-sb, .sect.content-sb, .cont-fluid.content-sb {
  justify-content: space-between; }

.content-sa, .sect.content-sa, .cont-fluid.content-sa {
  justify-content: space-around; }

.column {
  flex-direction: column; }

.row {
  flex-direction: row; }

.flex {
  display: flex; }

.flex-wrap {
  flex-flow: row wrap; }

.bg-d {
  background-color: #181819; }

.bord-l {
  border: 1px solid #fff; }

.h1 {
  font-size: 80px;
  line-height: 1;
  font-weight: 400; }

.h2 {
  font-size: 30px;
  font-weight: 300;
  margin: 40px 0; }

.h3 {
  font-size: 44px;
  font-weight: 400;
  margin-bottom: 60px; }

.h4 {
  font-size: 24px;
  font-weight: 400; }

.h5 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px; }

.toolbar {
  position: fixed;
  display: flex;
  justify-content: space-around;
  width: 100%;
  z-index: 2000;
  height: 40px;
  top: 0;
  left: 0;
  background: #181819; }
  .toolbar .links-right {
    display: flex; }
  .toolbar .cont-fluid-w {
    flex-direction: row; }
  .toolbar .flex {
    height: 100%; }
  .toolbar menu {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .toolbar menu li {
      position: relative; }
      .toolbar menu li:hover .submenu {
        opacity: 1;
        transform: rotate(0);
        transition: opacity 0.5s, transform 0s; }
  .toolbar .link {
    margin: 0 20px;
    color: #fff; }
  .toolbar .submenu {
    position: absolute;
    top: 28px;
    left: -10px;
    background-color: #181819;
    padding: 10px 20px 20px 20px;
    z-index: 2000;
    transition: opacity 0.5s, transform 0.5s 0.5s;
    transform-origin: 50% 0;
    transform: rotateX(90deg);
    opacity: 0; }
    .toolbar .submenu li {
      margin-bottom: 10px; }
      .toolbar .submenu li:last-child {
        margin-bottom: 0; }
    .toolbar .submenu .link {
      white-space: nowrap;
      margin: 0; }
  .toolbar img {
    height: 40px;
    width: auto;
    left: 60px; }
  .toolbar .toolbar-logo {
    display: flex;
    justify-content: space-around;
    color: #e16500;
    height: 100%; }
    .toolbar .toolbar-logo a {
      color: #fff; }
    .toolbar .toolbar-logo ul {
      position: absolute;
      left: 160px;
      top: 19px;
      width: auto;
      display: flex;
      align-items: center; }
      .toolbar .toolbar-logo ul li {
        margin-right: 20px; }

.mob-mnu {
  position: fixed;
  right: 0;
  width: 80%;
  padding: 15px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 0;
  background-color: #181819;
  overflow-y: auto; }
  .mob-mnu menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%; }
    .mob-mnu menu li {
      padding: 20px 20px 20px 40px;
      position: relative; }
      .mob-mnu menu li .angle-bottom {
        position: absolute;
        top: 18px;
        right: 50px;
        margin: auto;
        transition: 0.5s; }
      .mob-mnu menu li li {
        padding: 10px 10px 10px 20px; }
  .mob-mnu .screen-link {
    padding: 20px 0;
    font-size: 20px;
    transition: 0.5s; }
  .mob-mnu .logo {
    padding: 0;
    width: 90px;
    margin-left: 40px; }
    .mob-mnu .logo img {
      width: 100%; }
  .mob-mnu form {
    margin: 30px 0 20px 0; }
  .mob-mnu .submenu {
    display: none; }

.blocker {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(24, 24, 25, 0.5);
  z-index: -100;
  opacity: 0;
  transition: 0.5s;
  cursor: pointer; }

.to-top {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 991;
  opacity: 1;
  transition: 0.5s; }
  .to-top .screen-link:hover + .to-top-text {
    opacity: 1; }

.to-top-text {
  position: absolute;
  left: -80px;
  bottom: 0px;
  color: #e16500;
  opacity: 0;
  transition: 0.5s; }

.progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  background-color: #e16500;
  height: 2px;
  z-index: 2002; }

.hamburger {
  cursor: pointer;
  width: 20px;
  height: 10px;
  margin: auto;
  margin-left: 0;
  transition: 0.5s;
  position: fixed;
  z-index: 2001;
  display: none;
  top: 15px;
  right: 20px; }
  .hamburger::after, .hamburger::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 20px;
    background-color: #e16500;
    transition: 0.5s; }
  .hamburger::before {
    top: 0; }
  .hamburger::after {
    top: 10px; }

.inner-hamb {
  position: absolute;
  width: 20px;
  height: 1px;
  top: 5px;
  background-color: #e16500;
  transition: 0.5s; }

.notification {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 350px;
  width: 100%;
  z-index: 99999;
  background-color: #181819;
  padding: 20px;
  border: 1px solid #e16500;
  transform: translateX(100%);
  opacity: 0;
  transition: 0.5s; }
  .notification:hover {
    opacity: 1;
    transform: translateX(0); }

.popup {
  position: fixed;
  z-index: 9998;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(-50%); }

.close-popup {
  width: 25px;
  height: 25px;
  position: relative;
  transition: 0.5s;
  cursor: pointer;
  top: 10px;
  right: 10px;
  cursor: pointer;
  position: absolute;
  z-index: 9999; }
  .close-popup::after, .close-popup::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 3px;
    background-color: #181819;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .close-popup::after {
    transform: rotate(45deg); }
  .close-popup::before {
    transform: rotate(-45deg); }
  .close-popup:hover {
    transform: scale(1.2); }

#politics {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #181819;
  overflow-y: scroll;
  padding: 20px; }
  #politics .close-popup {
    width: 25px;
    height: 25px;
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    position: absolute; }
    #politics .close-popup::after, #politics .close-popup::before {
      content: '';
      position: absolute;
      width: 25px;
      height: 3px;
      background-color: #181819;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
    #politics .close-popup::after {
      transform: rotate(45deg); }
    #politics .close-popup::before {
      transform: rotate(-45deg); }
  #politics ul {
    padding-left: 10px; }
  #politics li {
    padding-left: 10px; }

#politics::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #e4e4e5; }

#politics::-webkit-scrollbar {
  width: 4px; }

#politics::-webkit-scrollbar-thumb {
  background-color: #181819; }

.callback {
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 300px;
  background-color: #fff;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 30px #cacacc;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }
  .callback form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    padding: 20px; }
    .callback form input:focus {
      color: #181819; }
  .callback .h4 {
    color: #181819;
    text-align: center;
    margin-bottom: 20px; }
  .callback .input-big {
    width: 280px;
    margin: 20px 0; }
  .callback .btn-d {
    width: 280px; }

.doc {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
  width: 50%; }

.no-js .liquid-slider {
  height: 350px;
  overflow: scroll; }

.ls-preloader {
  background: url(../images/loading.gif) #f2f2f2 no-repeat center 50%;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; }

/*** Containers ***/
.ls-wrapper {
  clear: both;
  overflow: auto;
  position: relative; }

.liquid-slider {
  background: #f2f2f2;
  width: 100%;
  margin: 0 auto;
  float: left;
  overflow: hidden;
  position: relative; }

.arrows .liquid-slider {
  /* Margin between slider and arrows */
  margin: 0 10px; }

.ls-responsive .liquid-slider {
  width: 70%;
  margin: 0; }

.panel-container {
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  /* from http://davidwalsh.name/translate3d */ }

.liquid-slider .panel-container .fade {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block; }

/*** Panels ***/
.panel-wrapper {
  padding: 15px 40px;
  position: relative; }

/*** Tabbed Navigation ***/
.currentCrossLink {
  font-weight: bold; }

.ls-nav ul {
  padding: 0;
  clear: both;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow: hidden; }

.ls-nav ul li {
  display: inline; }

.ls-nav ul li a {
  display: block;
  float: left;
  text-decoration: none;
  width: 100%; }

/*** Mobile Navigation ***/
.ls-select-box {
  width: 100%;
  height: 35px;
  overflow: hidden; }

.ls-select-box select {
  width: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  padding: 5px;
  font-size: 110%;
  border: none;
  height: 35px;
  cursor: pointer;
  outline: 0; }

/*** Arrow Navigation (non-graphical) ***/
.ls-nav-left, .ls-nav-right {
  top: 0;
  float: left;
  clear: both; }

.ls-nav-left a, .ls-nav-right a {
  background: #000;
  color: #fff;
  padding: 5px;
  width: 100px;
  display: block;
  text-align: center;
  text-decoration: none; }

.ls-responsive .ls-nav-left {
  position: absolute;
  left: 0;
  z-index: 2; }

.ls-responsive .ls-nav-left a {
  background: #9A9A9A;
  width: 80px; }

.ls-responsive .ls-nav-left a:hover {
  background: #747474; }

.ls-responsive .ls-nav-right {
  position: absolute;
  right: 0;
  z-index: 2; }

.ls-responsive .ls-nav-right a {
  background: #9A9A9A;
  width: 80px; }

.ls-responsive .ls-nav-right a:hover {
  background: #747474; }

/*** Arrow Navigation (graphical) ***/
.ls-nav-left-arrow, .ls-nav-right-arrow {
  cursor: pointer;
  float: left;
  clear: both; }

.ls-nav-left-arrow a, .ls-nav-right-arrow a {
  display: block; }

[class$="-arrow"] {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  margin-top: 50px;
  position: relative; }

.ls-nav-right-arrow {
  background-position: top right;
  margin-right: 5px; }

.ls-nav-left-arrow {
  background-position: top left;
  margin-left: 5px; }

.ls-nav-left-arrow:hover {
  background-position: bottom left; }

.ls-nav-right-arrow:hover {
  background-position: bottom right; }

.ls-responsive {
  height: 100%; }

.ls-nav-left-arrow, .ls-nav-right-arrow {
  background-image: none;
  bottom: 67px;
  z-index: 2;
  opacity: 1 !important;
  margin-top: 0 !important;
  transition: 0.5s; }
  .ls-nav-left-arrow:hover, .ls-nav-right-arrow:hover {
    transform: scale(1.2); }

.ls-nav-left-arrow {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: relative;
  position: absolute;
  left: 707px; }
  .ls-nav-left-arrow::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 25px;
    width: 25px;
    border-bottom: 1px solid #181819;
    border-left: 1px solid #181819;
    transform: rotate(45deg); }

.ls-nav-right-arrow {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: relative;
  position: absolute;
  left: 765px; }
  .ls-nav-right-arrow::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 25px;
    width: 25px;
    border-top: 1px solid #181819;
    border-right: 1px solid #181819;
    transform: rotate(45deg); }

.ls-responsive .liquid-slider {
  width: calc(100% - 320px);
  height: 700px; }
  .ls-responsive .liquid-slider .h3 {
    margin-bottom: 20px; }
  .ls-responsive .liquid-slider .ls-panel {
    height: 100%;
    float: left;
    background-color: #fff;
    overflow: hidden; }

#main-slider-nav-ul {
  width: 100%;
  height: 90%;
  background-color: #181819;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  padding-left: 80px;
  margin: 0; }
  #main-slider-nav-ul a {
    color: #fff; }
  #main-slider-nav-ul li {
    position: relative; }
    #main-slider-nav-ul li::after {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      background-color: #e16500;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      left: -60px;
      right: auto;
      transition: 0.5s; }
  #main-slider-nav-ul .current::after {
    width: 40px; }

.ls-nav {
  width: 320px;
  padding: 80px 20px 60px 0;
  background-color: #181819;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .ls-nav:hover {
    cursor: pointer; }

#main-slider-wrapper {
  border-radius: 2px;
  display: flex;
  float: none;
  box-shadow: 0 0 30px #cacacc;
  overflow: hidden; }

.panel-container {
  transition: 0.5s ease-in-out !important;
  height: 100%; }

.panel-wrapper {
  padding: 0;
  height: 100%;
  display: flex;
  overflow: hidden; }

.slider-title {
  padding-left: 80px;
  color: #e16500;
  margin-bottom: 60px; }

.ls-select-box {
  display: none !important; }

.fadeInDown {
  transform: translateY(80px);
  opacity: 0;
  transition: 0.5s; }

.fadeInUp {
  transform: translateY(-80px);
  opacity: 0;
  transition: 0.5s; }

.fadeInRight {
  transform: translateX(-80px);
  opacity: 0;
  transition: 0.5s; }

.fadeInLeft {
  transform: translateX(80px);
  opacity: 0;
  transition: 0.5s; }

.animator.fadeInDown {
  transform: translateY(0);
  opacity: 1; }

.animator.fadeInUp {
  transform: translateY(0);
  opacity: 1; }

.animator.fadeInRight {
  transform: translateX(0);
  opacity: 1; }

.animator.fadeInLeft {
  transform: translateX(0);
  opacity: 1; }

#sect-1 {
  height: calc(100% + 30px);
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 0; }
  #sect-1 .h1 {
    transform: rotateX(90deg);
    transition: 0.5s 0.5s;
    opacity: 0; }
  #sect-1 .h2 {
    transform: translateX(50%);
    opacity: 0;
    transition: 0.5s 1s; }
  #sect-1 .btn-a {
    transform: translateY(100%);
    opacity: 0;
    transition: 0.5s 1.5s; }
  #sect-1 .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100% - 100px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #181819; }

.dark {
  background-color: #181819; }
  .dark .cont-fluid-w {
    padding: 0 100px; }
  .dark .title {
    margin-bottom: 150px; }

.pluses {
  position: absolute;
  bottom: 0;
  padding: 0 20px; }

.plus {
  background: #fff;
  color: #181819;
  box-shadow: 0 5px 10px #cacacc;
  z-index: 100;
  padding: 0 0 0 80px;
  border-radius: 2px; }
  .plus img {
    margin-bottom: 20px;
    height: 35px;
    width: auto; }
  .plus .item-block {
    padding: 20px 20px 20px 0; }

#sect-plus {
  padding: 0; }
  #sect-plus .item-block img {
    transition: 0.5s; }
  #sect-plus .item-block:hover img {
    transform: translateY(-10px); }

#sect-2 .h1 {
  margin-bottom: 60px; }

#sect-2 .lig-slide {
  position: relative; }

#sect-2 .close {
  position: absolute;
  transition: 0s;
  z-index: 99;
  display: none; }
  #sect-2 .close::after, #sect-2 .close::before {
    background-color: #181819; }

#sect-2 .slider-img {
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  transition: 0.5s;
  cursor: pointer; }
  #sect-2 .slider-img img {
    height: 100%;
    width: auto;
    transition: 0.5s; }
  #sect-2 .slider-img:hover {
    opacity: 0.7; }

#sect-2 .description {
  width: 50%;
  height: 100%;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s; }

#sect-2 .slider-price {
  color: #e16500; }

#sect-2 .btn-l {
  margin-left: 10px;
  border: 1px solid #181819; }

.sl-price {
  margin: 10px 0; }

.text-a.sl-price {
  font-size: 20px; }

#sect-3 .btn-d-big, #sect-3 .btn-l-big {
  width: 400px; }

#sect-3 .thermPrice {
  margin-bottom: 40px; }

.innerCallback {
  margin: 60px 0; }

#sect-4 {
  background-size: cover; }
  #sect-4 span {
    color: #fff; }
  #sect-4 .sect4-subt {
    display: flex;
    align-items: center; }
    #sect-4 .sect4-subt span {
      margin-left: 20px;
      font-size: 30px; }
    #sect-4 .sect4-subt .sect4-num {
      font-size: 100px;
      font-weight: 600;
      margin: 0; }

#sect-5 .cont-fluid {
  padding: 0; }
  #sect-5 .cont-fluid:nth-child(2) {
    padding: 0 20px; }

#sect-5 .factory-image {
  padding: 20px;
  transition: transform 0.5s; }
  #sect-5 .factory-image:hover {
    transform: scale(1.2); }

#sect-5 img {
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 0 30px #cacacc; }

#sect-5 .manufac-desc {
  padding: 20px 20px 0 20px; }

#sect-6 {
  background-size: cover; }
  #sect-6 .row img {
    width: auto;
    height: 70px;
    margin-bottom: 40px; }
  #sect-6 .row .items {
    padding: 0 40px 0 0; }

#sect-7 .h4 {
  text-align: center;
  margin-bottom: 40px; }

#sect-7 form {
  text-align: center;
  box-shadow: 0 0 30px #cacacc;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 2px; }
  #sect-7 form .flex {
    padding: 20px 0; }

#sect-7 textarea {
  width: 100%;
  height: 100%; }

#sect-7 .input-group {
  height: 150px;
  position: relative; }
  #sect-7 .input-group .btn-d {
    position: absolute;
    bottom: 0;
    right: 0; }

#sect-7 label:first-child {
  margin-left: 0; }

#sect-7 label {
  font-size: 20px;
  white-space: nowrap;
  margin-left: 20px;
  margin-right: 10px; }

.item-info {
  display: flex;
  align-items: center;
  margin-bottom: 40px; }
  .item-info .icon {
    height: 30px;
    margin: 0;
    margin-right: 20px; }
  .item-info .link {
    color: #181819;
    text-transform: none;
    font-size: 20px;
    white-space: nowrap; }
    .item-info .link::after, .item-info .link::before {
      background-color: #181819; }

#sect-8 {
  padding-top: 20px;
  padding-bottom: 0; }
  #sect-8 #map .ymaps-2-1-65-controls__control_toolbar, #sect-8 #map .ymaps-2-1-64-_hidden-text, #sect-8 #map .ymaps-2-1-64-float-button-text {
    display: none; }

.footer {
  background-color: #181819;
  width: 100%;
  padding: 30px 0; }
  .footer .cont-fluid {
    align-items: normal; }
  .footer .link {
    text-transform: none; }
  .footer .footer-list {
    display: flex;
    justify-content: space-between;
    flex: 1; }
  .footer li li {
    padding: 10px 0; }
  .footer li img {
    height: 40px; }

.read-more {
  display: none;
  cursor: pointer; }

#gallery {
  padding: 40px 0 0 0; }

#notfound {
  height: 100%; }
  #notfound .h1, #notfound .h2, #notfound .link {
    color: #181819; }

.desc {
  margin-top: 40px;
  width: 100%; }
  .desc .h1, .desc h1 {
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 300; }
  .desc .h3, .desc h3 {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: 300; }
  .desc ul {
    display: block;
    list-style-type: disc; }
    .desc ul li {
      margin-bottom: 10px;
      padding: 0 0 0 40px;
      position: relative; }
      .desc ul li::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 1px;
        width: 20px;
        background-color: #e16500; }

#partners {
  padding: 0; }

.contacts {
  padding: 20px 0; }

.contacts-item {
  padding-bottom: 10px; }
  .contacts-item .link {
    color: #181819; }
    .contacts-item .link::after, .contacts-item .link::before {
      display: none; }
    .contacts-item .link:hover {
      color: #e16500; }
  .contacts-item strong {
    color: #181819;
    font-weight: 600; }

.partner {
  padding: 0 0 10px 20px; }

.sliderTip {
  display: none; }

.slider {
  width: 100%; }

.slide-line {
  overflow: hidden; }

.slide .img-center {
  width: 100%; }
  .slide .img-center img {
    width: 100%; }

.item {
  width: 100%;
  padding: 10px;
  opacity: 0;
  transition: 1s;
  padding: 0 50px 0 0; }
  .item img {
    width: 100px;
    height: 100px;
    border-radius: 100px; }
  .item .h4 {
    margin: 20px 0; }

.control {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 300;
  transition: 0.5s; }
  .control:hover {
    transform: scale(1.2); }

.prev {
  left: 30px; }

.next {
  right: 30px; }

.slider-dots {
  bottom: -30px;
  width: 100%;
  padding-top: 20px;
  left: 0;
  display: none;
  justify-content: flex-start;
  align-items: center;
  transition: 0.5s; }

.dot {
  width: 6px;
  height: 6px;
  background-color: #181819;
  margin: 0 20px 0 0;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s; }
  .dot:hover {
    width: 10px;
    height: 10px; }

.dot-active {
  width: 10px;
  height: 10px;
  justify-content: space-around;
  align-items: center; }

.filter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  padding-top: 30px; }

.filter-link {
  color: #fff;
  font-size: 24px;
  transition: 0.5s;
  position: relative; }
  .filter-link::before, .filter-link::after {
    content: '';
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transition: 0.5s; }
  .filter-link:hover::before, .filter-link:hover::after {
    left: -30px;
    width: 20px;
    height: 1px; }

.filter-active::before, .filter-active::after {
  left: -30px;
  width: 20px;
  height: 1px; }

.active-link::before, .active-link::after {
  width: 50%; }

.mob-mnu .active-link .link::before, .mob-mnu .active-link .link::after {
  width: 50%; }

.mob-mnu-active .page {
  transform: translateX(-80%); }

.mob-mnu-active .blocker {
  z-index: 9998;
  opacity: 1; }

.hamburger-active::after {
  transform: rotate(45deg);
  top: 5px; }

.hamburger-active::before {
  transform: rotate(-45deg);
  top: 5px; }

.hamburger-active .inner-hamb {
  opacity: 0;
  transform: translateX(100px); }

.appender-popup-wrap .blocker-popup {
  z-index: 9991;
  opacity: 1; }

.popup-open-popup .popup-opened-open-popup {
  opacity: 1;
  transform: translate(0); }

.popup-open-popup .blocker {
  z-index: 9991;
  opacity: 1; }

.active-slide .item {
  opacity: 1; }

.notification-visible {
  transform: translate(0);
  opacity: 1; }

.slider-img-active {
  cursor: none; }
  .slider-img-active .img-active {
    right: 0 !important;
    width: 100% !important; }
    .slider-img-active .img-active:hover {
      opacity: 1 !important;
      cursor: none !important; }
  .slider-img-active .ls-nav-left-arrow, .slider-img-active .ls-nav-right-arrow {
    opacity: 0 !important; }
  .slider-img-active .desc-hidden .description {
    opacity: 0; }
  .slider-img-active .close {
    display: block !important;
    cursor: none;
    pointer-events: none; }

.active-submenu .angle-bottom {
  transform: rotate(180deg);
  top: 24px !important; }

.ready #sect-1 .h1 {
  transform: rotateX(0deg);
  opacity: 1; }

.ready #sect-1 .h2 {
  transform: translateX(0);
  opacity: 1; }

.ready #sect-1 .btn-a {
  transform: translateY(0);
  opacity: 1; }

@media only screen and (max-width: 1700px) {
  #sect-2 .description {
    padding-right: 40px;
    padding-left: 40px; }
  .ls-nav-left-arrow {
    left: 705px; } }

@media only screen and (max-width: 1400px) {
  .h1 {
    font-size: 64px; }
  .ls-nav {
    width: 280px; }
  .ls-responsive .liquid-slider {
    width: calc(100% - 280px); }
  #sect-2 .description {
    width: 65%; }
  #sect-2 .slider-img {
    width: 35%; }
  .ls-nav-left-arrow {
    left: 677px; }
  .ls-nav-right-arrow {
    left: 765px; }
  #sect-4 .sect4-subt {
    flex-direction: column;
    text-align: center; }
    #sect-4 .sect4-subt .sect4-num {
      margin-left: 0; } }

@media only screen and (max-width: 1200px) {
  .h1 {
    font-size: 56px; }
  .h2 {
    font-size: 24px; }
  .h3 {
    font-size: 40px; }
  .toolbar .link {
    margin: 0 10px; }
  .ls-nav {
    width: 240px; }
  .ls-responsive .liquid-slider {
    width: calc(100% - 240px); }
  .slider-title {
    padding-left: 40px; }
  #main-slider-nav-ul {
    padding-left: 40px;
    font-size: 16px; }
  .ls-nav-left-arrow {
    left: 600px; }
  .ls-nav-right-arrow {
    left: 635px; }
  #sect-1 .cont-fluid-w {
    padding: 0 60px; }
  .pluses {
    padding: 0 20px; }
  .plus {
    padding-left: 40px; }
  #sect-2 .description {
    padding-left: 20px;
    padding-right: 20px; } }

@media only screen and (max-width: 992px) {
  body {
    font-size: 14px; }
  .h1 {
    font-size: 42px; }
  .h2 {
    font-size: 20px; }
  .h3 {
    font-size: 30px;
    margin-bottom: 40px; }
  .pad-top {
    padding-top: 80px; }
  .sect {
    padding: 40px 0; }
  .btn-d-big, .btn-l-big, .btn-a {
    font-size: 22px; }
  .toolbar menu {
    display: none; }
  .toolbar .cont-fluid-w {
    justify-content: flex-start; }
  .toolbar .btn-l {
    margin-left: 20px; }
  .toolbar .link {
    margin: 0 20px; }
  .hamburger {
    display: block; }
  #sect-1 {
    min-height: 100%;
    height: auto; }
    #sect-1 .bg {
      height: 100%; }
    #sect-1 .title {
      margin-bottom: 0; }
    #sect-1 .cont-fluid-w {
      padding: 0 20px;
      align-items: flex-start; }
  #sect-plus {
    padding: 60px 0 20px 0; }
  .pluses {
    position: static; }
  #sect-2 {
    padding-top: 40px; }
    #sect-2 .h1 {
      margin-bottom: 40px; }
    #sect-2 .ls-nav {
      background-color: #fff;
      border: 1px solid #181819; }
  #sect-4 .sect4-subt .sect4-num {
    font-size: 64px; }
  #sect-4 .sect4-subt span {
    font-size: 24px; }
  .desc {
    margin-top: 20px; }
  #sect-6 .row img {
    height: 40px;
    margin-bottom: 20px; }
  #sect-6 .row .items {
    padding-right: 20px; }
  #sect-7 {
    padding-bottom: 0; }
  .item-info .link {
    font-size: 16px; }
  .item-info .icon {
    height: 25px;
    margin-right: 10px; }
  #main-slider-nav-ul {
    position: absolute;
    display: block !important;
    height: auto;
    left: 0;
    top: 33px;
    padding: 20px;
    transform-origin: 50% 0;
    opacity: 0;
    transform: rotateX(90deg);
    transition: transform 0.5s 0.5s, opacity 0.5s; }
    #main-slider-nav-ul li {
      padding: 10px 0;
      display: block; }
      #main-slider-nav-ul li::after {
        display: none; }
      #main-slider-nav-ul li a {
        float: none;
        transition: 0.5s; }
        #main-slider-nav-ul li a:hover {
          color: #e16500; }
    #main-slider-nav-ul .current a {
      color: #e16500; }
  .ls-nav {
    position: absolute;
    z-index: 99;
    top: 0;
    padding: 10px 40px 10px 20px;
    width: 100%; }
    .ls-nav::after {
      content: '';
      position: absolute;
      top: 10px;
      right: 20px;
      margin: auto;
      height: 10px;
      width: 10px;
      transition: 0.5s;
      border-top: 1px solid #181819;
      border-left: 1px solid #181819;
      transform: rotate(225deg); }
    .ls-nav:focus #main-slider-nav-ul {
      transform: rotate(0deg);
      opacity: 1;
      transition: opacity 0.5s; }
    .ls-nav:focus::after {
      transform: rotate(45deg); }
  #main-slider-nav-ul.main-slider-nav-ul-active {
    transform: rotate(0deg);
    opacity: 1;
    transition: opacity 0.5s; }
  .slider-title {
    margin-bottom: 0;
    padding: 0;
    color: #181819; }
  .ls-responsive .liquid-slider {
    width: 100%; }
  .ls-nav-left-arrow {
    left: 365px; }
  .ls-nav-right-arrow {
    left: 410px; }
  #sect-3 .btn-d-big, #sect-3 .btn-l-big {
    width: 300px; } }

@media only screen and (max-width: 768px) {
  .h1 {
    font-size: 28px; }
  .h2 {
    font-size: 16px;
    margin: 20px 0; }
  .h3 {
    font-size: 24px;
    margin-bottom: 30px; }
  .h4 {
    font-size: 18px; }
  .doc {
    width: 80%; }
  p {
    margin-bottom: 10px; }
  .btn-d-big, .btn-l-big, .btn-a {
    padding: 10px 20px;
    font-size: 18px; }
  .btn-d, .btn-l {
    font-size: 14px;
    padding: 10px 20px; }
  input {
    font-size: 14px; }
  textarea {
    font-size: 14px; }
  .sect {
    padding: 30px 0; }
  #sect-1 .cont-fluid-w {
    padding: 0 20px; }
  #sect-plus {
    padding: 30px 0 10px 0; }
  .plus {
    padding-left: 20px; }
  #sect-2 {
    padding: 30px 0; }
    #sect-2 .slider-img {
      width: 20%; }
    #sect-2 .description {
      width: 80%; }
  .ls-responsive .liquid-slider {
    height: 500px; }
  .ls-nav-left-arrow {
    left: 300px; }
  .ls-nav-right-arrow {
    left: 350px; }
  #sect-3 .btn-d-big, #sect-3 .btn-l-big {
    width: 250px; }
  #sect-4 .sect4-subt .sect4-num {
    font-size: 40px; }
  #sect-4 .sect4-subt span {
    font-size: 16px;
    margin-left: 10px; }
  #sect-5 .factory-image {
    padding: 10px; }
  #sect-5 .cont-fluid {
    padding: 0 10px; }
  #sect-6 .row .items {
    padding-top: 20px; }
  #sect-6 .h3 {
    margin-bottom: 10px; }
  #sect-7 .h4 {
    margin-bottom: 20px; }
  #sect-7 form {
    padding: 10px; }
    #sect-7 form .flex {
      padding: 10px 0; }
  #sect-7 label {
    font-size: 14px; }
  .item-info {
    margin-bottom: 20px; }
    .item-info .icon {
      height: 20px; }
    .item-info .link {
      font-size: 16px; }
  .footer {
    font-size: 12px; }
    .footer li li {
      padding: 5px 0; }
  .desc .h1, .desc h1 {
    font-size: 34px;
    margin-bottom: 20px; }
  .desc .h3, .desc h3 {
    font-size: 24px;
    margin-bottom: 10px; } }

@media only screen and (max-width: 560px) {
  .btn-l {
    font-size: 14px; }
  .toolbar .link {
    margin: 0 10px; }
  .toolbar .btn-l {
    margin-left: 10px;
    padding: 10px; }
  .ls-nav-left-arrow {
    right: 80px;
    left: auto; }
  .ls-nav-right-arrow {
    right: 30px;
    left: auto; }
  .ls-nav-left-arrow, .ls-nav-right-arrow {
    display: none !important; }
  #sect-2 .description {
    width: 100%;
    padding-bottom: 20px; }
  #sect-2 .slider-img {
    position: static;
    width: 100%; }
    #sect-2 .slider-img:hover {
      opacity: 1; }
    #sect-2 .slider-img img {
      width: 100%;
      height: 224px; }
  .slider-img-active {
    cursor: pointer;
    display: none !important; }
    .slider-img-active .img-active:hover {
      opacity: 1 !important;
      cursor: pointer !important; }
    .slider-img-active .ls-nav-left-arrow, .slider-img-active .ls-nav-right-arrow {
      opacity: 0 !important; }
    .slider-img-active .desc-hidden .description {
      opacity: 1; }
    .slider-img-active .close {
      display: none !important; }
  .ls-responsive .liquid-slider {
    height: auto; }
  .panel-wrapper {
    height: 100%;
    flex-direction: column; }
    .panel-wrapper .scrollable {
      display: none; }
  #sect-7 form .flex {
    flex-flow: row wrap; }
  #sect-7 label {
    margin: 10px 0;
    text-align: left; }
  #sect-7 input {
    margin: 10px 0; }
  .footer .footer-list > li {
    width: 50%;
    padding-bottom: 10px;
    padding-right: 10px; }
  .desc {
    overflow: hidden; }
    .desc .h1, .desc h1, .desc .h3, .desc h3, .desc li, .desc p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .read-more {
    display: block; }
  .read-more-active .h1, .read-more-active h1, .read-more-active .h3, .read-more-active h3, .read-more-active li, .read-more-active p {
    white-space: normal;
    text-overflow: inherit; }
  #sect-8 {
    display: none; }
  .sliderTip {
    display: block;
    font-size: 8px;
    margin-top: 11px; } }

@media only screen and (max-width: 480px) {
  body {
    font-size: 12px; }
  *::-webkit-scrollbar {
    width: 2px; }
  .h1 {
    font-size: 30px; }
  .h3 {
    font-size: 22px;
    margin-bottom: 20px; }
  .sect {
    padding: 20px 0; }
  .btn-l, .btn-d {
    padding: 5px 10px;
    font-size: 12px; }
  .text-a.sl-price {
    font-size: 18px; }
  #sect-4 .sect4-subt .sect4-num {
    font-size: 24px; }
  #sect-4 .sect4-subt span {
    font-size: 10px;
    margin-left: 10px; }
  .item-info .link {
    font-size: 14px; }
  .item-info .icon {
    height: 15px; }
  .desc .h1, .desc h1 {
    font-size: 24px; }
  .desc .h3, .desc h3 {
    font-size: 20px; } }

@media only screen and (max-width: 420px) {
  .toolbar {
    height: 30px; }
    .toolbar img {
      height: 30px; }
    .toolbar .btn-l {
      font-size: 12px;
      padding: 7px; }
    .toolbar .link {
      font-size: 12px; }
  .hamburger {
    top: 10px; }
  #main-slider-nav-ul {
    font-size: 14px; }
  .crumbs {
    top: 30px; } }

@media only screen and (max-width: 406px) {
  .ls-nav-left-arrow, .ls-nav-right-arrow {
    top: 210px; } }

@media only screen and (max-width: 380px) {
  .toolbar .btn-l {
    display: none; }
  .ls-nav-left-arrow, .ls-nav-right-arrow {
    top: 209px; }
  .ls-nav-left-arrow {
    right: 62px; } }

@media only screen and (max-width: 375px) {
  .ls-nav-left-arrow, .ls-nav-right-arrow {
    top: 198px; } }
