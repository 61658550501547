@import "../vars";

@for $i from 1 through 1 {
  .cont-fluid {
    width: nth($xl-cont-size, $i); }
  @for $j from 1 through $grid-column {
    .#{nth($prefix, $i)}-col-#{$j} {
      width: (100 / $grid-column * $j) + %; } } }
@for $i from 1 through length($prefix) {
  @if nth($brakepoint, $i) {
    @media only screen and (max-width: nth($brakepoint, $i)) {
      .cont-fluid {
        width: nth($cont-sizes, $i); }
      @for $j from 1 through $grid-column {
        .#{nth($prefix, $i)}-col-#{$j} {
          width: (100 / $grid-column * $j) + %; } } } } }

@for $i from 1 through 1 {
  .cont-fluid-w {
    width: nth($xl-cont-size-w, $i); } }
@for $i from 1 through length($brakepoint-w) {
  @if nth($brakepoint-w, $i) {
    @media only screen and (max-width: nth($brakepoint-w, $i)) {
      .cont-fluid-w {
        width: nth($cont-sizes-w, $i); } } } }
