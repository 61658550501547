
.no-js .liquid-slider {
  height:350px;
  overflow:scroll;
}
.ls-preloader {
  background: url(../images/loading.gif) #f2f2f2 no-repeat center 50%;
  opacity:1;
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:2;
}

/*** Containers ***/
.ls-wrapper {
  clear: both;
  overflow: auto;
  position: relative;
}
.liquid-slider {
  background: #f2f2f2;
  width: 100%;
  margin: 0 auto;
  float: left;
  overflow: hidden;
  position: relative;
}
.arrows .liquid-slider {
  /* Margin between slider and arrows */
  margin: 0 10px;
}
.ls-responsive .liquid-slider {
  width: 70%;
  margin: 0;
}
.panel-container {
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  /* from http://davidwalsh.name/translate3d */
}
.liquid-slider .panel-container .fade {
  width:100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

/*** Panels ***/

.panel-wrapper {
  padding: 15px 40px;
  position:relative;
}

/*** Tabbed Navigation ***/

.currentCrossLink {
  font-weight: bold;
}
.ls-nav ul {
  padding:0;
  clear: both;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow: hidden;
}
.ls-nav ul li {
  display: inline;
}
.ls-nav ul li a {
  display: block;
  float: left;
  text-decoration: none;
  width: 100%;
}

/*** Mobile Navigation ***/
.ls-select-box {
  width: 100%;
  height: 35px;
  overflow: hidden;
}
.ls-select-box select {
  width: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance:none;
  background: transparent;
  padding: 5px;
  font-size: 110%;
  border: none;
  height: 35px;
  cursor:pointer;
  outline: 0;
}

/*** Arrow Navigation (non-graphical) ***/
.ls-nav-left, .ls-nav-right {
  top:0;
  float: left;
  clear:both;
}
.ls-nav-left a, .ls-nav-right a {
  background: #000;
  color: #fff;
  padding: 5px;
  width: 100px;
  display: block;
  text-align: center;
  text-decoration: none;
}
.ls-responsive .ls-nav-left {
  position: absolute;
  left: 0;
  z-index: 2;
}
.ls-responsive .ls-nav-left a {
  background: #9A9A9A;
  width: 80px;
}
.ls-responsive .ls-nav-left a:hover {
  background: #747474;
}
.ls-responsive .ls-nav-right {
  position: absolute;
  right: 0;
  z-index: 2;
}
.ls-responsive .ls-nav-right a {
  background: #9A9A9A;
  width: 80px;
}
.ls-responsive .ls-nav-right a:hover {
  background: #747474;
}

/*** Arrow Navigation (graphical) ***/
.ls-nav-left-arrow, .ls-nav-right-arrow {
  cursor: pointer;
  float: left;
  clear: both;
}
.ls-nav-left-arrow a, .ls-nav-right-arrow a {
  display: block;
}
[class$="-arrow"] {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  margin-top: 50px;
  position:relative;
}
.ls-nav-right-arrow {
  background-position: top right;
  margin-right: 5px;
}
.ls-nav-left-arrow {
  background-position: top left;
  margin-left: 5px;
}
.ls-nav-left-arrow:hover {
  background-position: bottom left;
}
.ls-nav-right-arrow:hover {
  background-position: bottom right;
}
