.fadeInDown {
  transform: translateY(80px);
  opacity: 0;
  transition: 0.5s; }
.fadeInUp {
  transform: translateY(-80px);
  opacity: 0;
  transition: 0.5s; }
.fadeInRight {
  transform: translateX(-80px);
  opacity: 0;
  transition: 0.5s; }
.fadeInLeft {
  transform: translateX(80px);
  opacity: 0;
  transition: 0.5s; }
.animator.fadeInDown {
  transform: translateY(0);
  opacity: 1; }
.animator.fadeInUp {
  transform: translateY(0);
  opacity: 1; }
.animator.fadeInRight {
  transform: translateX(0);
  opacity: 1; }
.animator.fadeInLeft {
  transform: translateX(0);
  opacity: 1; }
