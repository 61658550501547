.toolbar {
	position: fixed;
	display: flex;
	justify-content: space-around;
	width: 100%;
	z-index: 2000;
	height: 40px;
	top: 0;
	left: 0;
	background: $d;
	.links-right {
		display: flex; }
	.cont-fluid-w {
		flex-direction: row; }
	.flex {
		height: 100%; }
	menu {
		width: auto;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		li {
			position: relative;
			&:hover {
				.submenu {
					opacity: 1;
					transform: rotate(0);
					transition: opacity 0.5s, transform 0s; } } } }
	.link {
		margin: 0 $space;
		color: $l; }
	.submenu {
		position: absolute;
		top: 28px;
		left: -10px;
		background-color: $d;
		padding: $space / 2 $space $space $space;
		z-index: 2000;
		transition: opacity 0.5s, transform 0.5s 0.5s;
		transform-origin: 50% 0;
		transform: rotateX(90deg);
		opacity: 0;
		li {
			margin-bottom: $space / 2;
			&:last-child {
				margin-bottom: 0; } }
		.link {
			white-space: nowrap;
			margin: 0; } }
	img {
		height: 40px;
		width: auto;
		left: 60px; }
	.toolbar-logo {
		display: flex;
		justify-content: space-around;
		color: $a;
		height: 100%;
		a {
			color: $l; }
		ul {
			position: absolute;
			left: 160px;
			top: 19px;
			width: auto;
			display: flex;
			align-items: center;
			li {
				margin-right: $space; } } } }

.mob-mnu {
	position: fixed;
	right: 0;
	width: 80%;
	padding: 15px 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	z-index: 0;
	background-color: $d;
	overflow-y: auto;
	menu {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		li {
			padding: $space $space $space $space * 2;
			position: relative;
			.angle-bottom {
				position: absolute;
				top: 18px;
				right: 50px;
				margin: auto;
				transition: 0.5s; }
			li {
				padding: $space / 2 $space / 2 $space / 2 $space; } } }
	.screen-link {
		padding: 20px 0;
		font-size: 20px;
		transition: 0.5s; }
	.logo {
		padding: 0;
		width: 90px;
		margin-left: $space * 2;
		img {
			width: 100%; } }
	form {
		margin: 30px 0 20px 0; }
	.submenu {
		display: none; } }

.blocker {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba($d, 0.5);
	z-index: -100;
	opacity: 0;
	transition: 0.5s;
	cursor: pointer; }

.to-top {
	position: fixed;
	bottom: 10px;
	right: 20px;
	z-index: 991;
	opacity: 1;
	transition: 0.5s;
	.screen-link:hover + .to-top-text {
		opacity: 1; } }

.to-top-text {
	position: absolute;
	left: -80px;
	bottom: 0px;
	color: $a;
	opacity: 0;
	transition: 0.5s; }

.progress {
	position: fixed;
	top: 0;
	left: 0;
	width: 0;
	background-color: $a;
	height: 2px;
	z-index: 2002; }

.hamburger {
	cursor: pointer;
	width: 20px;
	height: 10px;
	margin: auto;
	margin-left: 0;
	transition: 0.5s;
	position: fixed;
	z-index: 2001;
	display: none;
	top: 15px;
	right: 20px;
	&::after,&::before {
		content: '';
		position: absolute;
		height: 1px;
		width: 20px;
		background-color: $a;
		transition: 0.5s; }
	&::before {
		top: 0; }
	&::after {
		top: 10px; } }
.inner-hamb {
	position: absolute;
	width: 20px;
	height: 1px;
	top: 5px;
	background-color: $a;
	transition: 0.5s; }

.notification {
	position: fixed;
	top: 0;
	right: 0;
	max-width: 350px;
	width: 100%;
	z-index: 99999;
	background-color: $d;
	padding: $space;
	border: $bord-a;
	transform: translateX(100%);
	opacity: 0;
	transition: 0.5s;
	&:hover {
		opacity: 1;
		transform: translateX(0); } }

.popup {
	position: fixed;
	z-index: 9998;
	transition: 0.5s;
	opacity: 0;
	transform: translateY(-50%); }
.close-popup {
	@include close-icon (25px, $d, 3px);
	top: 10px;
	right: 10px;
	cursor: pointer;
	position: absolute;
	z-index: 9999;
	&:hover {
		transform: scale(1.2); } }
#politics {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $l;
	color: $d;
	overflow-y: scroll;
	padding: $space;
	.close-popup {
		@include close-icon (25px, $d, 3px);
		position: absolute; }
	ul {
		padding-left: 10px; }
	li {
		padding-left: 10px; } }
#politics::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px lighten($d, 80%); }
#politics::-webkit-scrollbar {
	width: 4px; }
#politics::-webkit-scrollbar-thumb {
	background-color: $d; }

.callback {
	width: 100%;
	max-width: 500px;
	height: 100%;
	max-height: 300px;
	background-color: $l;
	border: $bord-l;
	box-shadow: 0 0 30px lighten($d, 70%);
	@include center-mode;
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: relative;
		padding: $space;
		input:focus {
			color: $d; } }
	.h4 {
		color: $d;
		text-align: center;
		margin-bottom: $space; }
	.input-big {
		width: 280px;
		margin: $space 0; }
	.btn-d {
		width: 280px; } }
.doc {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	right: 0;
	z-index: 100;
	width: 50%; }
