@import "_mixins/font-face";

@include font-face("montserrat-regular", "../fonts/MontserratRegular/Montserrat-Regular");
@include font-face("montserrat-regular", "../fonts/MontserratLight/Montserrat-Light", light);
@include font-face("montserrat-regular", "../fonts/MontserratSemibold/Montserrat-SemiBold", 600);
@include font-face("montserrat-regular", "../fonts/MontserratBlack/Montserrat-Black", 900);
// +font-face("roboto-example", "../fonts/Roboto/RobotoBold", bold)
// +font-face("roboto-example", "../fonts/Roboto/Roboto-Light", light)
// +font-face("roboto-example", "../fonts/Roboto/Roboto-Medium", 500)
// +font-face("roboto-example", "../fonts/Roboto/Roboto-Black", 900)

// font-face("ProximaNova", "../fonts/ProximaNova/ProximaNova-Regular")
// +font-face("ProximaNova", "../fonts/ProximaNova/ProximaNova-Bold", bold)
// font-face("ProximaNova", "../fonts/ProximaNova/ProximaNova-Light", light)

// +font-face("raleway", "../fonts/Raleway/Raleway-Regular")
// +font-face("raleway", "../fonts/Raleway/Raleway-Bold", bold)
// +font-face("raleway", "../fonts/Raleway/Raleway-Black", 900)
// +font-face("raleway", "../fonts/Raleway/Raleway-ExtraBold", 800)
// +font-face("raleway", "../fonts/Raleway/Raleway-Medium", 500)

//+font-face("open-sans", "../fonts/Open Sans/OpenSans-Regular")
// +font-face("open-sans", "../fonts/Open Sans/OpenSans-Bold", bold)
// +font-face("open-sans", "../fonts/Open Sans/OpenSans-Light", light)
// +font-face("open-sans", "../fonts/Open Sans/OpenSans-SemiBold", 600)
