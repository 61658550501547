@import "../libs/liquidslider/css/liquid-slider";

.ls-responsive {
  height: 100%; }
.ls-nav-left-arrow, .ls-nav-right-arrow {
  background-image: none;
  bottom: 67px;
  z-index: 2;
  opacity: 1!important;
  margin-top: 0!important;
  transition: 0.5s;
  &:hover {
    transform: scale(1.2); } }
.ls-nav-left-arrow {
  @include angle-left (25px, $d, 1px);
  position: absolute;
  left: 320px + 387px; }
.ls-nav-right-arrow {
  @include angle-right (25px, $d, 1px);
  position: absolute;
  left: 320px + 445px; }
.ls-responsive {
  .liquid-slider {
    width: calc(100% - 320px);
    height: 700px;
    .h3 {
      margin-bottom: $space; }
    .ls-panel {
      height: 100%;
      float: left;
      background-color: $l;
      overflow: hidden; } } }
#main-slider-nav-ul {
  width: 100%;
  height: 90%;
  background-color: $d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  padding-left: $space * 4;
  margin: 0;
  a {
    color: $l; }
  li {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      background-color: $a;
      @include center-mode;
      left: -60px;
      right: auto;
      transition: 0.5s; } }
  .current {
    &::after {
      width: 40px; } } }
.ls-nav {
  width: 320px;
  padding: $space * 4 $space $space * 3 0;
  background-color: $d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    cursor: pointer; } }
#main-slider-wrapper {
  border-radius: $radius;
  display: flex;
  float: none;
  box-shadow: 0 0 30px lighten($d, 70%);
  overflow: hidden; }
.panel-container {
  transition: 0.5s ease-in-out!important;
  height: 100%; }
.panel-wrapper {
  padding: 0;
  height: 100%;
  display: flex;
  overflow: hidden; }
.slider-title {
  padding-left: $space * 4;
  color: $a;
  margin-bottom: $space * 3; }
.ls-select-box {
  display: none!important; }
