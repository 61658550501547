.h1 {
  font-size: 80px;
  line-height: 1;
  font-weight: 400; }
.h2 {
  font-size: 30px;
  font-weight: 300;
  margin: $space * 2 0; }
.h3 {
  font-size: 44px;
  font-weight: 400;
  margin-bottom: $space * 3; }
.h4 {
  font-size: 24px;
  font-weight: 400; }
.h5 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: $space; }
