.page {
	overflow: hidden;
	transition: 0.4s;
	height: 100%;
	width: 100%;
	background-color: $l;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	-webkit-overflow-scrolling: touch; }
.sect {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	overflow: hidden;
	padding: $space * 3 0;
	-webkit-overflow-scrolling: touch; }
.items-page {
	padding: $space * 3 0 0 0; }
.screen-scroll {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-y: scroll;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch; }
.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%; }
.scrollable {
	overflow-y: auto;
	overflow-x: hidden;
	transition: 0.5s;
	padding-right: $space; }
.scrollable::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px lighten($a, 80%); }
.scrollable::-webkit-scrollbar {
	width: 4px; }
.scrollable::-webkit-scrollbar-thumb {
	background-color: $a; }
.screen-link {
	cursor: pointer; }
.polit {
	text-align: center;
	font-size: 8px;
	line-height: 1.2;
	color: $d;
	.open-popup {
		border-bottom: $bord-d;
		color: $d; } }
.pad-top {
	padding-top: $space * 2; }
.crumbs {
	padding: $space;
	position: fixed;
	top: 40px;
	left: 0;
	width: 100%;
	background-color: $l;
	z-index: 1000;
	ul {
		width: 100%;
		display: flex;
		flex-flow: row wrap; }
	.link {
		color: $d;
		&::after, &::before {
			background-color: $d; } } }
////SLIDER////
.slider {
	position: relative;
	cursor: pointer;
	z-index: 999; }
.viewport {
	width: 100%;
	overflow: hidden;
	position: relative;
	transition: 0.5s; }
.slide-line {
	transition: 0.5s;
	overflow-y: hidden; }
.slide {
	float: left; }
///////////////
.text-d {
	color: $d; }

.bg-overlay-d {
	&::after {
		content: '';
		position: absolute;
		background-color: $d;
		@include center-mode;
		width: 100%;
		height: 100%;
		opacity: 0.8;
		z-index: 1; } }
.bg-overlay-l {
	&::after {
		content: '';
		position: absolute;
		background-color: $l;
		@include center-mode;
		width: 100%;
		height: 100%;
		opacity: 0.95;
		z-index: 1; } }

span {
	color: $a; }

.item-flex {
	display: flex; }
.item-block {
	padding: 0 50px 0 0; }
.icon {
	height: 60px;
	margin-bottom: 40px;
	img {
		max-height: 100%; } }

.tabbar {
	position: fixed;
	top: 60px;
	left: 0;
	width: 100%;
	padding: 0 0 0 255px;
	display: flex;
	z-index: 200;
	flex-flow: row wrap; }
.tab-view {
	position: absolute;
	top: 98px;
	z-index: -100;
	opacity: 0;
	transform: translateY(100%);
	transition: 0.5s;
	width: 100%;
	height: calc(100% - 98px); }
