@import "vars";

@media only screen and (max-width : 1700px) {
	#sect-2 {
		.description {
			padding: {
				right: $space * 2;
				left: $space * 2; } } }
	.ls-nav-left-arrow {
		left: 705px; } }

@media only screen and (max-width : 1400px) {
	.h1 {
		font-size: 64px; }
	.ls-nav {
		width: 280px; }
	.ls-responsive .liquid-slider {
		width: calc(100% - 280px); }
	#sect-2 {
		.description {
			width: 65%; }
		.slider-img {
			width: 35%; } }
	.ls-nav-left-arrow {
		left: 677px; }
	.ls-nav-right-arrow {
		left: 765px; }
	#sect-4 {
		.sect4-subt {
			flex-direction: column;
			text-align: center;
			.sect4-num {
				margin-left: 0; } } } }

@media only screen and (max-width : 1200px) {
	.h1 {
		font-size: 56px; }
	.h2 {
		font-size: 24px; }
	.h3 {
		font-size: 40px; }
	.toolbar {
		.link {
			margin: 0 $space / 2; } }
	.ls-nav {
		width: 240px; }
	.ls-responsive .liquid-slider {
		width: calc(100% - 240px); }
	.slider-title {
		padding-left: $space * 2; }
	#main-slider-nav-ul {
		padding-left: $space * 2;
		font-size: 16px; }
	.ls-nav-left-arrow {
		left: 600px; }
	.ls-nav-right-arrow {
		left: 635px; }
	#sect-1 {
		.cont-fluid-w {
			padding: 0 $space * 3; } }
	.pluses {
		padding: 0 $space; }
	.plus {
		padding-left: $space * 2; }
	#sect-2 {
		.description {
			padding: {
				left: $space;
				right: $space; } } } }

@media only screen and (max-width : 992px) {
	body {
		font-size: 14px; }
	.h1 {
		font-size: 42px; }
	.h2 {
		font-size: 20px; }
	.h3 {
		font-size: 30px;
		margin-bottom: $space * 2; }
	.pad-top {
		padding-top: $space * 4; }
	.sect {
		padding: $space * 2 0; }
	.btn-d-big, .btn-l-big, .btn-a {
		font-size: 22px; }
	.toolbar {
		menu {
			display: none; }
		.cont-fluid-w {
			justify-content: flex-start; }
		.btn-l {
			margin-left: $space; }
		.link {
			margin: 0 $space; } }
	.hamburger {
		display: block; }
	#sect-1 {
		min-height: 100%;
		height: auto;
		.bg {
			height: 100%; }
		.title {
			margin-bottom: 0; }
		.cont-fluid-w {
			padding: 0 $space;
			align-items: flex-start; } }
	#sect-plus {
		padding: $space * 3 0 $space 0; }
	.pluses {
		position: static; }
	#sect-2 {
		padding-top: $space * 2;
		.h1 {
			margin-bottom: $space * 2; }
		.ls-nav {
			background-color: $l;
			border: 1px solid $d; } }
	#sect-4 {
		.sect4-subt {
			.sect4-num {
				font-size: 64px; }
			span {
				font-size: 24px; } } }
	.desc {
		margin-top: $space; }
	#sect-6 {
		.row {
			img {
				height: 40px;
				margin-bottom: $space; }
			.items {
				padding-right: $space; } } }
	#sect-7 {
		padding-bottom: 0; }
	.item-info {
		.link {
			font-size: 16px; }
		.icon {
			height: 25px;
			margin-right: $space / 2; } }
	#main-slider-nav-ul {
		position: absolute;
		display: block!important;
		height: auto;
		left: 0;
		top: 33px;
		padding: $space;
		transform-origin: 50% 0;
		opacity: 0;
		transform: rotateX(90deg);
		transition: transform 0.5s 0.5s, opacity 0.5s;
		li {
			padding: $space / 2 0;
			display: block;
			&::after {
				display: none; }
			a {
				float: none;
				transition: 0.5s;
				&:hover {
					color: $a; } } }
		.current {
			a {
				color: $a; } } }
	.ls-nav {
		position: absolute;
		z-index: 99;
		top: 0;
		padding: $space / 2 $space * 2 $space / 2 $space;
		width: 100%;
		&::after {
			content: '';
			position: absolute;
			top: 10px;
			right: 20px;
			margin: auto;
			height: 10px;
			width: 10px;
			transition: 0.5s;
			border: {
				top: 1px solid $d;
				left: 1px solid $d; }
			transform: rotate(225deg); }
		&:hover {
			// #main-slider-nav-ul
			// 	transform: rotate(0deg)
			// 	opacity: 1
			// 	transition: opacity 0.5s
			// &::after
			// 	transform: rotate(45deg)
 }			// 	top: 15px
		&:focus {
			#main-slider-nav-ul {
				transform: rotate(0deg);
				opacity: 1;
				transition: opacity 0.5s; }
			&::after {
				transform: rotate(45deg); } } }
	#main-slider-nav-ul.main-slider-nav-ul-active {
	  transform: rotate(0deg);
	  opacity: 1;
	  transition: opacity 0.5s; }
	.slider-title {
		margin-bottom: 0;
		padding: 0;
		color: $d; }
	.ls-responsive .liquid-slider {
		width: 100%; }
	.ls-nav-left-arrow {
		left: 365px; }
	.ls-nav-right-arrow {
		left: 410px; }
	#sect-3 {
		.btn-d-big, .btn-l-big {
			width: 300px; } } }
@media only screen and (max-width : 768px) {
	$space: 10px;
	.h1 {
		font-size: 28px; }
	.h2 {
		font-size: 16px;
		margin: $space * 2 0; }
	.h3 {
		font-size: 24px;
		margin-bottom: $space * 3; }
	.h4 {
		font-size: 18px; }
	.doc {
		width: 80%; }
	p {
		margin-bottom: $space; }
	.btn-d-big, .btn-l-big, .btn-a {
		padding: $space $space * 2;
		font-size: 18px; }
	.btn-d, .btn-l {
		font-size: 14px;
		padding: $space $space * 2; }
	input {
		font-size: 14px; }
	textarea {
		font-size: 14px; }
	.sect {
		padding: $space * 3 0; }
	#sect-1 .cont-fluid-w {
		padding: 0 $space * 2; }
	#sect-plus {
		padding: $space * 3 0 $space 0; }
	.plus {
		padding-left: $space * 2; }
	#sect-2 {
		padding: $space * 3 0;
		.slider-img {
			width: 20%; }
		.description {
			width: 80%; } }
	.ls-responsive .liquid-slider {
		height: 500px; }
	.ls-nav-left-arrow {
		left: 300px; }
	.ls-nav-right-arrow {
		left: 350px; }
	#sect-3 {
		.btn-d-big, .btn-l-big {
			width: 250px; } }
	#sect-4 {
		.sect4-subt {
			.sect4-num {
				font-size: 40px; }
			span {
				font-size: 16px;
				margin-left: $space; } } }
	#sect-5 {
		.factory-image {
			padding: $space; }
		.cont-fluid {
			padding: 0 $space; } }
	#sect-6 {
		.row {
			.items {
				padding-top: $space * 2; } }
		.h3 {
			margin-bottom: $space; } }
	#sect-7 {
		.h4 {
			margin-bottom: $space * 2; }
		form {
			padding: $space;
			.flex {
				padding: $space 0; } }
		label {
			font-size: 14px; } }
	.item-info {
		margin-bottom: $space * 2;
		.icon {
			height: 20px; }
		.link {
			font-size: 16px; } }
	.footer {
		font-size: 12px;
		li li {
			padding: $space / 2 0; } }
	.desc {
		.h1, h1 {
			font-size: 34px;
			margin-bottom: $space * 2; }
		.h3, h3 {
			font-size: 24px;
			margin-bottom: $space; } } }
@media only screen and (max-width : 560px) {
	$space: 10px;
	.btn-l {
		font-size: 14px; }
	.toolbar {
		.link {
			margin: 0 $space; }
		.btn-l {
			margin-left: $space;
			padding: $space; } }
	.ls-nav-left-arrow {
		right: 80px;
		left: auto; }
	.ls-nav-right-arrow {
		right: 30px;
		left: auto; }
	.ls-nav-left-arrow, .ls-nav-right-arrow {
		display: none!important; }
	#sect-2 {
		.description {
			width: 100%;
			padding-bottom: $space * 2; }
		.slider-img {
			position: static;
			width: 100%;
			&:hover {
				opacity: 1; }
			img {
				width: 100%;
				height: 224px; } } }
	.slider-img-active {
	  cursor: pointer;
	  .img-active {
	    &:hover {
	      opacity: 1!important;
	      cursor: pointer!important; } }
	  .ls-nav-left-arrow, .ls-nav-right-arrow {
	    opacity: 0!important; }
			display: none!important;
	  .desc-hidden {
	    .description {
	      opacity: 1; } }
	  .close {
	    display: none!important; } }
	.ls-responsive {
		.liquid-slider {
			height: auto; } }
	.panel-wrapper {
		height: 100%;
		flex-direction: column;
		.scrollable {
			display: none; } }
	#sect-7 {
		form {
			.flex {
				flex-flow: row wrap; } }
		label {
			margin: $space 0;
			text-align: left; }
		input {
			margin: $space 0; } }
	.footer .footer-list > li {
		width: 50%;
		padding-bottom: $space;
		padding-right: $space; }
	.desc {
		overflow: hidden;
		.h1, h1, .h3, h3, li, p {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap; } }
	.read-more {
		display: block; }
	.read-more-active {
		.h1, h1, .h3, h3, li, p {
	    white-space: normal;
	    text-overflow: inherit; } }
	#sect-8 {
		display: none; }
	.sliderTip {
		display: block;
		font-size: 8px;
		margin-top: 11px; } }
@media only screen and (max-width : 480px) {
	$space: 10px;
	body {
		font-size: 12px; }
	*::-webkit-scrollbar {
		width: 2px; }
	.h1 {
		font-size: 30px; }
	.h3 {
		font-size: 22px;
		margin-bottom: $space * 2; }
	.sect {
		padding: $space * 2 0; }
	.btn-l, .btn-d {
		padding: $space / 2 $space;
		font-size: 12px; }
	.text-a.sl-price {
		font-size: 18px; }
	#sect-4 {
		.sect4-subt {
			.sect4-num {
				font-size: 24px; }
			span {
				font-size: 10px;
				margin-left: $space; } } }
	.item-info {
		.link {
			font-size: 14px; }
		.icon {
			height: 15px; } }
	.desc {
		.h1, h1 {
			font-size: 24px; }
		.h3, h3 {
			font-size: 20px; } } }

@media only screen and (max-width : 420px) {
	.toolbar {
		height: 30px;
		img {
			height: 30px; }
		.btn-l {
			font-size: 12px;
			padding: 7px; }
		.link {
			font-size: 12px; } }
	.hamburger {
		top: 10px; }
	#main-slider-nav-ul {
		font-size: 14px; }
	.crumbs {
		top: 30px; } }
@media only screen and (max-width : 406px) {
	.ls-nav-left-arrow, .ls-nav-right-arrow {
		top: 210px; } }

@media only screen and (max-width : 380px) {
	.toolbar {
		.btn-l {
			display: none; } }
	.ls-nav-left-arrow, .ls-nav-right-arrow {
		top: 209px; }
	.ls-nav-left-arrow {
		right: 62px; } }
@media only screen and (max-width : 375px) {
	.ls-nav-left-arrow, .ls-nav-right-arrow {
		top: 198px; } }
